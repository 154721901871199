import React, { Component } from 'react';
import styled from 'styled-components';
import Layout from '../../components/layout';
import { graphql } from 'gatsby';
import SEO from '../../components/seo';
import { Waypoint } from 'react-waypoint';
import { GatsbyImage } from 'gatsby-plugin-image';
import {
  COLOR_GREEN,
  COLOR_GRAY,
  COLOR_WHITE,
  COLOR_TEXT_GRAY,
  MAX_WIDTH,
  MAX_WIDTH_SITE,
  screen,
  StyledA,
} from '../../components/common/variables';
import IconOne from '../../components/services/assets/service-icon-1-retro.svg';
import IconTwo from '../../components/services/assets/service-icon-2.svg';
import IconThree from '../../components/services/assets/service-icon-3.svg';
import IconFour from '../../components/services/assets/service-icon-4.svg';
import IconFive from '../../components/services/assets/service-icon-5-retro.svg';
import Arrow from '../../components/services/assets/arrow.svg';
import mobileIcon1 from '../../images/service-icon-1.svg'; // I had to use icons from different location for mobile screen
import mobileIcon2 from '../../images/service-icon-2.svg';
import mobileIcon3 from '../../images/service-icon-3.svg';
import mobileIcon4 from '../../images/service-icon-4.svg';
import mobileIcon5 from '../../images/service-icon-5.svg';
import FeaturedSection from '../../components/FeaturedSection';

const Banner = styled.div`
  max-width: ${MAX_WIDTH_SITE};
  margin: 0 auto;
  @media ${screen.largeXL} {
    padding-top: 0;
  }

  .small-banner {
    max-width: 100%;
    @media ${screen.largeXL} {
      display: none;
    }
  }
  .large-banner {
    display: none;
    @media ${screen.largeXL} {
      display: block;
    }
  }
`;

const Wrapper = styled.div`
  margin: 0 auto;

  .retro-service {
    max-width: ${MAX_WIDTH};
    margin: 0 auto;
    padding: 40px 35px 0 35px;
    @media ${screen.largeXXL} {
      padding: 90px 0 0 0;
    }

    &__title {
      color: ${COLOR_GREEN};
      font-size: 1.5rem;
      font-weight: 400;
      margin: 0;
      @media ${screen.medium} {
        font-size: 1.8rem;
      }
      @media ${screen.largeXL} {
        font-size: 2.2rem;
      }
    }

    &__content {
      max-width: 760px;
      margin-top: 15px;
      @media ${screen.medium} {
        margin-top: 30px;
      }

      p {
        margin-bottom: 20px;
        font-size: 1.05rem;
        color: ${COLOR_TEXT_GRAY};
        @media ${screen.medium} {
          font-size: 1.1rem;
          margin-bottom: 30px;
        }
      }
    }

    &__contact {
      background: ${COLOR_GREEN};
      max-width: ${MAX_WIDTH};
      text-align: center;
      padding: 35px;
      margin: 0 auto 0 auto;
      @media ${screen.largeXL} {
        background: none;
        text-align: initial;
        margin: 50px auto 100px auto;
      }
      h2 {
        color: ${COLOR_WHITE};
        font-size: 1.2rem;
        font-weight: 400;
        @media ${screen.largeXL} {
          color: ${COLOR_GREEN};
          font-size: 1.7rem;
        }
      }
      p {
        font-size: 1rem;
        margin-top: 5px;
        color: ${COLOR_GRAY};
        @media ${screen.largeXL} {
          color: ${COLOR_TEXT_GRAY};
          font-size: 1.1rem;
          margin-top: 10px;
        }
      }

      a {
        margin: 20px auto 0 auto;
        background: ${COLOR_WHITE};
        color: ${COLOR_GREEN};
        @media ${screen.medium} {
          padding: 0.6rem 2rem 0.4rem 2rem;
        }

        &:hover {
          border: 2px ${COLOR_GREEN} solid;
        }
        @media ${screen.largeXL} {
          margin: 20px 0 0 0;
          background: ${COLOR_GREEN};
          color: ${COLOR_WHITE};
          &:hover {
            border: 2px ${COLOR_GRAY} solid;
            background: ${COLOR_GRAY};
          }
        }
      }
    }

    &__mobile-banner {
      margin-bottom: 1.2rem;
      width: 100%;
      height: 216px;
      background-position: center center;
      background-size: cover;
      @media ${screen.largeXL} {
        display: none;
      }
    }
  }
`;

const SVGContainer = styled.div`
  max-width: 1170px;
  align-items: flex-start;
  justify-content: space-evenly;
  padding: 100px 0 15px 0;
  margin-left: -55px;
  margin: 0 auto;
  position: sticky;
  top: 0;
  z-index: 8;
  display: none;
  background: ${COLOR_WHITE};
  @media ${screen.largeXL} {
    display: flex;
  }

  svg {
    width: 100px;
    height: 100px;
  }

  .green-arrows {
    width: 20px !important;
    margin: 0 30px;
  }

  .icon-1 {
    svg {
      #fill-colour path {
        fill: ${COLOR_GREEN} !important;
      }
    }
  }
  .icon-2 {
    svg {
      #fill-colour path {
        fill: ${COLOR_GREEN} !important;
      }
      #fill-colour circle {
        fill: ${COLOR_GREEN} !important;
        transition: all 1s ease-out;
      }
    }
  }
  .icon-3 {
    svg {
      #fill-colour path {
        fill: ${COLOR_GREEN} !important;
      }
    }
  }
  .icon-4 {
    svg {
      #fill-colour path {
        fill: ${COLOR_GREEN} !important;
      }
      #fill-colour circle {
        fill: ${COLOR_GREEN} !important;
      }
      #fill-colour rect {
        fill: ${COLOR_GREEN} !important;
      }
    }
  }
  .icon-5 {
    svg {
      #fill-colour path {
        fill: ${COLOR_GREEN} !important;
      }
      #fill-colour circle {
        fill: ${COLOR_GREEN} !important;
      }
      #fill-colour polyline {
        stroke: ${COLOR_GREEN} !important;
      }
    }
  }
`;

const ListWrapper = styled.div`
  .items {
    display: flex;
    padding: 0 0 45px 0;
    @media ${screen.largeXL} {
      padding: 50px 0 50px 0;
    }
  }

  .images {
    max-width: 230px;
    width: 100%;
    margin-right: 40px;
    display: none;
    @media ${screen.largeXL} {
      display: block;
    }
  }

  .texts {
    max-width: 760px;

    .title-container {
      display: flex;

      .number {
        font-size: 1.65rem;
        margin: -1px;
        margin-right: 5px;
        color: #3a3a3a;
        font-weight: 400;
      }

      .main {
        color: ${COLOR_GRAY};
        font-size: 1.6rem;
        font-weight: 400;
        margin-bottom: 10px;
        line-height: 2rem;
        @media ${screen.medium} {
          font-size: 1.7rem;
        }
        @media ${screen.largeXL} {
          font-size: 1.8rem;
        }
      }
    }

    p {
      color: ${COLOR_TEXT_GRAY};
      line-height: 30px;
    }
  }

  .mobile-icons {
    width: 60px;
    height: 60px;
    margin-bottom: 15px;
    @media ${screen.medium} {
      width: 100px;
      height: 100px;
    }
    @media ${screen.largeXL} {
      display: none;
    }
  }
`;

class RetrofitServicePage extends Component {
  state = {
    isHighlighted: {
      icon1: false,
      icon2: false,
      icon3: false,
      icon4: false,
      icon5: false,
    },
    mobileIcons: [
      // Store icons in array for mobile screen to loop thru
      mobileIcon1,
      mobileIcon2,
      mobileIcon3,
      mobileIcon4,
      mobileIcon5,
    ],
  };

  render() {
    const { data } = this.props;
    const title = this.props.data.content.title;
    const content = this.props.data.content.content;
    const phoneNumber = '1300 739 913';
    const infoList = this.props.data.content.acfService.informationList;

    const { isHighlighted, mobileIcons } = this.state;

    const renderList = infoList.map((service, i) => {
      return (
        <Waypoint
          topOffset="30%"
          bottomOffset="69.99%"
          onEnter={() => {
            isHighlighted[`icon${i + 1}`] = true;
            this.setState({ isHighlighted });
          }}
          onLeave={() => {
            isHighlighted[`icon${i + 1}`] = false;
            this.setState({ isHighlighted });
          }}
          key={i}
        >
          <div className="items">
            <div className="images">
              <GatsbyImage
                image={service.image.localFile.childImageSharp.gatsbyImageData}
                aria-hidden="true"
                alt={service.image.altText || 'Source Separation Systems'}
              />
            </div>
            <div className="texts">
              <img className="mobile-icons" src={mobileIcons[i]} alt="z" />
              <div className="title-container">
                <div className="number">{service.number}.</div>
                <h2 className="main">{service.title}</h2>
              </div>
              <p>{service.description}</p>
            </div>
          </div>
        </Waypoint>
      );
    });
    return (
      <Layout>
        <SEO
          title={data.content.acfService.seo.page_title || title}
          description={data.content.acfService.seoDescription}
        />
        <Wrapper>
          {data.content.acfService.imageBanner && (
            <Banner>
              <GatsbyImage
                className="large-banner"
                alt={`${title} - Source Separation Systems`}
                image={
                  data.content.acfService.imageBanner.localFile.childImageSharp
                    .gatsbyImageData
                }
              />

              <div className="small-banner">
                <GatsbyImage
                  alt={`${title} - Source Separation Systems`}
                  image={
                    data.smallScreenBanner.acfService.imageBanner.localFile
                      .childImageSharp.gatsbyImageData
                  }
                />
              </div>
            </Banner>
          )}
          <div className="retro-service">
            <h1 className="retro-service__title">{title}</h1>
            <div
              className="retro-service__content"
              dangerouslySetInnerHTML={{ __html: content }}
            />

            <SVGContainer>
              <div className={isHighlighted.icon1 ? 'icon-1' : null}>
                <IconOne />
              </div>
              <Arrow className="green-arrows" />
              <div className={isHighlighted.icon2 ? 'icon-2' : null}>
                <IconTwo />
              </div>
              <Arrow className="green-arrows" />
              <div className={isHighlighted.icon3 ? 'icon-3' : null}>
                <IconThree />
              </div>
              <Arrow className="green-arrows" />
              <div className={isHighlighted.icon4 ? 'icon-4' : null}>
                <IconFour />
              </div>
              <Arrow className="green-arrows" />
              <div className={isHighlighted.icon5 ? 'icon-5' : null}>
                <IconFive />
              </div>
            </SVGContainer>
            <ListWrapper>{renderList}</ListWrapper>
          </div>
          <div className="retro-service__contact">
            <h2>Need a custom design?</h2>
            <p>Get in touch to find out how we can help you</p>
            <StyledA transparent href={`tel:${phoneNumber}`}>
              Call {phoneNumber}
            </StyledA>
          </div>
        </Wrapper>

        <FeaturedSection content={this.props.data.featuredContent.edges} />
      </Layout>
    );
  }
}

export default RetrofitServicePage;

export const serviceData = graphql`
  {
    content: wpService(id: { eq: "cG9zdDo1MTc=" }) {
      title
      content
      acfService {
        seo {
          pageTitle
          description
        }
        informationList {
          number
          title
          description
          image {
            altText
            localFile {
              childImageSharp {
                gatsbyImageData(layout: FULL_WIDTH)
              }
            }
          }
        }
        imageBanner {
          localFile {
            childImageSharp {
              gatsbyImageData(layout: FULL_WIDTH)
            }
          }
        }
      }
    }

    smallScreenBanner: wpService(id: { eq: "cG9zdDo1MTc=" }) {
      acfService {
        imageBanner {
          localFile {
            childImageSharp {
              gatsbyImageData(layout: CONSTRAINED, width: 1080, height: 624)
            }
          }
        }
      }
    }

    featuredContent: allWpPost(
      limit: 3
      sort: { fields: modified, order: DESC }
      filter: {
        tags: { nodes: { elemMatch: { name: { eq: "Service Retrofit" } } } }
      }
    ) {
      edges {
        node {
          id
          title
          slug
          acfBlog {
            content
            featuredImage {
              localFile {
                childImageSharp {
                  gatsbyImageData(layout: CONSTRAINED, width: 670, height: 490)
                }
              }
            }
          }
        }
      }
    }
  }
`;
